<template>
    <section id="section-home">
        <div id="main-bg-image" class="bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg.jpg') + `)`"></div>
        <div class="panel-tt">
            <div class="panel-tt2">
                <img class="show-desktop" src="@/assets/images/home/ttw.png"/>
                <img class="show-mobile" src="@/assets/images/home/mobile-top.png"/>
            </div>
        </div>
        <div class="panel-outer">
            <div class="panel">
                <div class="panel2">
                    <!--
                    <div class="l1 style1 stagger1">From the director of ANNABELLE</div>

                    <div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>
                    -->
                    <!--
                    <div class="topdate1 stagger1">

                    </div>

                  -->

                    <div >
                        <div class="topdate1 stagger1">
                            AVAILABLE
                        </div>
                        <div class="topdate2 stagger1">
                            ON DIGITAL
                        </div>
                    </div>



                    <div class="gettix stagger1">
                        <div class="l-small">
                            <button @click="openAction" class="img-not-txt">
                                <div class="bgimg" :style="`background-image: url(` + require('@/assets/images/home/btn-watch-now-bg.png') + `)`"></div>
                                <span>WATCH NOW</span>
                            </button>
                        </div>
                    </div>

                    <CountDownClock @countdownFinishedEmit="handleCountdownFinished" class="stagger1" v-show="!countdownFinished" ></CountDownClock>


                    <div class="wrap stagger1" v-show="countdownFinished">
                        <div class="l1 style1">INSPIRED BY TRUE EVENTS</div>

                        <button class="ytplayer" @click="gh.openOverlay('video')">
                            <div class="bg">
                                <img src="@/assets/images/home/player.jpg"/>
                            </div>
                            <div class="icon stagger1">
                                <div class="icon2">
                                    <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
                                </div>
                            </div>
                        </button>
                    </div>


                    <div class="l3 style1 signup stagger1">sign up for updates and exclusive content</div>
                    <div class="signup stagger1">
                        <EmailSignup
                            mcDomain="https://lullabymovieofficial.us20.list-manage.com"
                            mcUserId="b25b645d846865015940afb4d"
                            mcListId="ab49050273"
                        ></EmailSignup>
                    </div>

                    <!--
                    <div class="show-mobile stagger1">
                        <span class="topdate1">
                            IN THEATERS
                        </span>
                        <span class="topdate2">
                            JUNE 23
                        </span>
                    </div>


                    <div class="btn-outer stagger1">
                        <a href="https://www.dropbox.com/sh/ryfbpb50f32vwq7/AACjbwnRVSueZCq6vVPKKb6Ma?dl=0" target="_blank" rel="noreferrer" >
                            <button class="btn">
                                <span>PRESS/EPK</span>
                            </button>

                        </a>
                    </div>
                    -->

                    <!--
                  <div class="hashtag stagger1">#LilithIsHere</div>
                  -->
                </div>
            </div>

        </div>

        <div class="panel-tt-bottom">
            <img src="@/assets/images/home/mobile-bottom.png"/>
        </div>

        <div id="order" :class="[(isOpen) ? 'open' : '']">
            <div @click="closeAction" class="scrim-closer"></div>
            <div class="order-inner">
                <div class="order-inner2">
                    <div class="tt-wide">
                        <img src="@/assets/images/home/tt-popup.png"/>
                    </div>

                    <div class="bugs">

                        <button class="bug">
                            <a href="https://www.amazon.com/gp/video/detail/B0BZSPLDKF/ref=atv_hm_sto_1_c_RLbnEd_HS27e0f8_1_2?jic=16%7CCgNhbGwSA2FsbA%3D%3D" target="_blank">
                                <img src="@/assets/images/order/amazon.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://tv.apple.com/us/movie/god-is-a-bullet/umc.cmc.4i45v76hnwm9p8b6h9hk78o2q" target="_blank">
                                <img src="@/assets/images/order/apple.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.xfinity.com/stream/entity/4850981929934407112" target="_blank">
                                <img src="@/assets/images/order/xfinity.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://play.google.com/store/movies/details/God_is_a_Bullet?id=tYaCXQPFNV0.P" target="_blank">
                                <img src="@/assets/images/order/google.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.microsoft.com/en-us/p/god-is-a-bullet/8d6kgwxzkpg3?activetab=pivot:overviewtab" target="_blank">
                                <img src="@/assets/images/order/microsoft.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.redbox.com/ondemand-movies/god-is-a-bullet" target="_blank">
                                <img src="@/assets/images/order/redbox.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://tv.verizon.com/watch/movie/God-Is-a-Bullet/MV020128090000?idType=TMS&qualifiedId=TMS-MV020128090000" target="_blank">
                                <img src="@/assets/images/order/verizon.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.vudu.com/content/movies/details/God-Is-a-Bullet/2411865" target="_blank">
                                <img src="@/assets/images/order/vudu.png"/>
                            </a>
                        </button>
                        <!--
                        <button class="bug">
                            <a href="https://www.fandango.com/malum-2023-231368/movie-overview?date=2023-03-30" target="_blank">
                                <img src="@/assets/images/order/fandango.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.regmovies.com/movies/malum/ho00014157#/buy-tickets-by-film-for-cinemas?at=2023-03-30&for-movie=ho00014157&view-mode=list" target="_blank">
                                <img src="@/assets/images/order/regal.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.readingcinemasus.com/townsquare/film/malum" target="_blank">
                                <img src="@/assets/images/order/reading.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.consolidatedtheatres.com/ward/film/malum" target="_blank">
                                <img src="@/assets/images/order/consolidated.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://drafthouse.com/show/malum" target="_blank">
                                <img src="@/assets/images/order/alamo.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.amctheatres.com/movies/malum-72498" target="_blank">
                                <img src="@/assets/images/order/amc.png"/>
                            </a>
                        </button>

                        <button class="bug">
                            <a href="https://www.amctheatres.com/movies/god-is-a-bullet-73029" target="_blank">
                                <img src="@/assets/images/order/amc.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.regmovies.com/movies/god-is-a-bullet/ho00014339#/" target="_blank">
                                <img src="@/assets/images/order/regal.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href=" https://www.fandango.com/god-is-a-bullet-2023-231897/movie-overview" target="_blank">
                                <img src="@/assets/images/order/fandango.png"/>
                            </a>
                        </button>
                        -->



                    </div>
                    <div class="hashtag">#SayYourPrayers</div>
                    -->
                </div>
            </div>
            <div @click="closeAction" class="close-button-outer">
                <div class="close-button"></div>
            </div>
        </div>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
import EmailSignup from '@/components/blocks/EmailSignup'
import CountDownClock from '@/components/blocks/CountDownClock'

export default {
    name: "HomePage",
    components: {
        CountDownClock,
        EmailSignup
    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
            countdownFinished: false
        }
    },
    mounted() {
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    methods: {
        escapeButtonPressed(e) {
            if (e.key === "Escape") {
                this.closeAction()
            }
        },
        openAction() {
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        },
        handleCountdownFinished(val) {
            this.countdownFinished = val
        }
    }
}
</script>