<template>
    <div  id="countdown-output">
        <div id="countdown-output-bg" class="abs100" :style="`background-image: url(` + require('@/assets/images/home/countdown-rip.png') + `)`"></div>
        <div class="style2 countdown" id="countdown-output-copy">{{countdownOutput }}</div>
        <!--
        <div id="countdown-output-bg2" class="abs100" :style="`background-image: url(` + require('@/assets/images/home/countdown-rip-noise.png') + `)`"></div>
        -->
    </div>
</template>

<script>
export default {
    name: "CountDownClock",
    data() {
        return {
            countDownDate: null,
            countdownInterval: null,
            countdownOutput: '',
            countdownFinished: false
        }
    },
    mounted() {
        // Set the countdown date and time
        this.countDownDate = new Date("May 10, 2023 10:00:00 GMT-0700").getTime();


        // Update the countdown every 1 second
        this.countdownInterval = setInterval(this.countdownTick, 1000); // 1000 milliseconds = 1 second
    },
    methods: {
        countdownTick() {

            // Get the current date and time
            const now = new Date().getTime();

            // Calculate the difference between the current date/time and the countdown date/time
            const difference = this.countDownDate - now;

            // Calculate days, hours, minutes, and seconds
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            // Pad single-digit numbers with a zero in front
            const formattedDays = String(days).padStart(2, "0");
            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");
            const formattedSeconds = String(seconds).padStart(2, "0");

            // Output the countdown in the format "dd:hh:mm:ss"
            this.countdownOutput = formattedDays + ":" + formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;


            // If the countdown is finished, stop updating the countdown
            if (difference < 0) {
                this.countdownFinished = true
                this.$emit('countdownFinishedEmit', this.countdownFinished );
                clearInterval(this.countdownInterval);
            }
        }
    }
}
</script>

