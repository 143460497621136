<template>
    <div v-show="isOpen" id="overlay">
        <div @click="closeAction" class="scrim-closer"></div>
        <div id="overlay-center-hook" class="inner">
            <div class="close-catcher abs100" @click="closeAction"></div>
            <div id="overlay-center-hook2" class="inner2">
                <YouTubeCustom v-if="youTubeId" ref="ytCustom" :youTubeId="youTubeId"></YouTubeCustom>
            </div>
        </div>
        <div @click="closeAction" class="close-button-outer">
            <div class="close-button"></div>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import gsap, {Power3} from 'gsap/all'
import {mapState} from 'vuex'
import YouTubeCustom from "@/components/blocks/YouTubeCustom"

export default {
    name: "OverlayFrame",
    components: {
        YouTubeCustom
    },
    data() {
        return {
            document: document,
            window: window,
            gh: gh,

            isOpen: false,
            obj: null,

            youTubeId: 'sBQaY-Id5nc' //if null, it will hide itself fq0eu1ICb5E

        }
    },
    computed: {
        ...mapState({
            overlayObject: state => state.overlayObject,
        }),
    },
    mounted() {
        this.window.addEventListener('resize', this.onResize)
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    watch: {
        overlayObject: {
            deep: true,

            // We have to move our method to a handler field
            handler(obj) {
                this.obj = obj
                if (obj) {
                    this.reveal()
                } else {
                    this.unreveal()
                }

            }
        },
    },
    methods: {
        reveal() {
            if (this.obj.type === 'video') {
                this.$refs.ytCustom.playVideo()
            }

            //load in overlay
            let tl = gsap.timeline({
                onComplete: this.reveal2
            })
            tl.fromTo('#overlay', 0.5,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    ease: Power3.easeInOut
                },
                0
            )

            this.isOpen = true
        },
        reveal2() {
            //
        },
        unreveal() {
            let tl = gsap.timeline({
                onComplete: this.unreveal2
            })
            tl.to('#overlay', 0.5,
                {
                    opacity: 0,
                    ease: Power3.easeInOut
                },
                0
            )
        },
        unreveal2() {
            this.$refs.ytCustom.stopVideo()
            this.isOpen = false
        },

        closeAction() {
            gh.closeOverlay()
        },


        escapeButtonPressed(e) {
            if (this.isOpen) {
                if (e.key === "Escape") {
                    this.closeAction()
                }
            }
        },
        onResize() {
            //handler if we dont have aspect ratio
            gsap.set('#overlay-center-hook2',
                {
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                }
            )
        },//onresize

    },
}
</script>
