<template>
  <HeaderFramework></HeaderFramework>
  <HomePage></HomePage>
  <OverlayFrame></OverlayFrame>
</template>
<script>
import HomePage from './components/pages/HomePage'
import OverlayFrame from "@/components/framework/OverlayFrame"
import HeaderFramework from "@/components/framework/HeaderFramework";
import gsap, {Power3, Linear} from "gsap/all";
export default {
  name: 'App',
  components: {
    HeaderFramework,
    HomePage,
    OverlayFrame
  },
  data() {
    return {
      window:window,
      document:document,
    }
  },
  mounted() {
    this.window.addEventListener('resize', this.resize)
    this.resize()
    setTimeout(this.reveal, 1000)
  },
  methods: {
    resize () {
      // get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = this.window.innerHeight * 0.01;
      this.document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    reveal() {
      let tl = gsap.timeline({

      })
      tl.fromTo('#main-bg-image', 0.5,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            ease: Linear.easeNone,
            stagger:0.1
          },
          0
      )
        tl.fromTo('.panel-tt', 1.5,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                ease: Linear.easeNone
            },
            0.25
        )

      tl.fromTo('.stagger1', 1.5,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            ease: Power3.easeInOut,
            stagger:0.1,
            delay:0,
          },
          0
      )
      tl.fromTo('#header-container', 2,
          {
            opacity: 0,
            y:-50
          },
          {
            opacity: 1,
            y:0,
            ease: Power3.easeInOut,
            delay:0.75
          },
          0
      )
    }
  }
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>